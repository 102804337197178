<template>
  <base-page
    class="login"
    centered
  >
    <base-graphic
      class="graphic"
      :image="require('@/assets/images/graphic/email-key.svg')"
      title="Login Key"
      body="To continue please enter the key that has been sent to you."
    />

    <form
      class="form"
      method="POST"
      @submit.prevent="login"
    >
      <form-input
        v-model="form.token"
        placeholder="Your login key"
        name="token"
        :disabled="loading"
        :invalid="notFound"
        full-width
      />
      <base-group
        align="center"
        spacing="medium"
        direction="vertical"
      >
        <base-button
          class="submit"
          label="Submit"
          :loading="loading"
          full-width
          @click="submit"
        />
        <base-button
          label="Don't have a key?"
          link
          :disabled="loading"
          @click="$router.push({ name: 'login-request' })"
        />
      </base-group>
    </form>
  </base-page>
</template>

<script>
import Form from '@/utils/Form';

export default {
  props: {
    token: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      form: new Form({
        token: this.$route.query.token || this.token,
      }),
      notFound: false,
    };
  },
  computed: {
    loading() {
      return this.$store.getters['auth/isLoading'];
    },
  },
  created() {
    // Autologin if token is set
    if (this.form.token) {
      this.submit();
    }
  },
  methods: {
    async submit() {
      // Save token
      await this.$store.dispatch('auth/setToken', this.form.token);
      // Get user details
      await this.$store.dispatch('auth/fetchAuthenticated').then(() => {
        // User details fetched, move to onboarding route
        this.$router.push({ name: 'onboarding' });
      }).catch(() => {
        // Clear token
        // Save token
        this.$store.dispatch('auth/setToken', null);
        // Invalid token
        this.notFound = true;
      });
    },

  },
};
</script>

<style lang="scss" scoped>
.graphic {
  margin-bottom: 32px;
}
</style>
